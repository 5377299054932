import React, { Component } from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"

import Button from "../../common/Button/button"

import "./textImage.scss"

class SectionTextImage extends Component {
    render() {

        return (
            <section className="container space-2-bottom space-3-bottom--lg">
                <div className={`row ${this.props.containerTextRight ? "justify-content-start" : "justify-content-end"}`}>
                    <div className={`col-xl-6 col-lg-6 col-md-6 col-12 ${this.props.containerTextRight ? "" : "order-md-2"}`}>
                        <Img className="section__img" fluid={this.props.image} alt={this.props.imageAlt} />
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                        <div className={`section__content section__content-${this.props.color}`}>   
                            {this.props.titleUrl != null && this.props.titleUrl.length > 0
                                ?   <h2 className="section__content-h2"><Link to={this.props.titleUrl} title={`${this.props.title1} ${this.props.title2}`}>
                                        <span className="section__content-h2-color">{this.props.title1}</span>{this.props.title2}</Link>
                                    </h2>
                                :   <h2 className="section__content-h2">
                                        <span className="section__content-h2-color">{this.props.title1}</span>{this.props.title2}
                                    </h2>
                            }
                            <p className="section__content-text space-1">{this.props.text}</p>
                            {this.props.buttonUrl != null && this.props.buttonUrl.length > 0
                                && <Button color={this.props.color} to={this.props.buttonUrl} text={this.props.button}>{this.props.button}</Button>
                            }
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default SectionTextImage